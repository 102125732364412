import styled, { css } from 'styled-components';
import { Colors, FontSize, FontSizePx, FontWeight } from 'styles/enums';
import { LinksContainerTypes, ImpressumTypes, FooterLinkTypes } from './types';
import { generateHighlight, mediaQueries } from 'styles/helpers';
import { Sizes } from 'styles/constants';
import Link from 'next/link';

export const FooterContainer = styled.footer`
  width: 100%;
  min-height: 310px;
  margin-top: 156px;
  margin-bottom: 40px;
  padding: 0px 4.5%;

  > div[data-cy='Grid'] {
    height: auto;
    max-width: ${Sizes.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0px;
    border-top: 1px solid ${Colors.Primary.base030};
    border-bottom: 1px solid ${Colors.Primary.base030};

    ${mediaQueries(
      'lg',
      css`
        height: 200px;
        padding: 13px 0px 18px 0px;
      `
    )}
  }

  ${mediaQueries(
    'lg',
    css`
      padding: 0px 7.5%;
    `
  )}
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 120px;
  max-height: 142px;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    object-fit: contain;
  }
`;

export const FooterInformation = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 22px;
  margin-top: auto;

  h2 {
    font-weight: ${FontWeight.Medium};
    font-size: ${FontSizePx.navlink};
  }

  p {
    font-size: ${FontSize.XLarge};
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-top: 28px;
  border-left: none;

  ${mediaQueries(
    'lg',
    css`
      border-left: 4px solid ${Colors.Yellow.base100};
      margin-top: 8px;
    `
  )}
`;

export const LinksContainer = styled.div<LinksContainerTypes>`
  width: ${({ $buttonsList }) => $buttonsList ? '100%' : 'auto'};
  display: ${({ $buttonsList }) => $buttonsList ? 'flex' : 'none'};
  justify-content: ${({ $buttonsList }) => $buttonsList ? 'flex-start' : 'space-around'};
  gap: ${({ $buttonsList }) => $buttonsList ? '16px' : '0px'};
  z-index: 1;

  > a.footer-link {
    width: fit-content;
    font-size: ${FontSize.Big};
    color: ${Colors.Gray.base};
  }

  > .footer-button {
    width: 48%;
    max-width: 189px;
    justify-content: center;

    ${mediaQueries(
      'lg',
      css`
        width: auto;
      `
    )}
  }

  ${mediaQueries(
    'lg',
    css`
      width: auto;
      display: flex;
      flex-direction: column;
    `
  )}
`;

export const Impressum = styled.div<ImpressumTypes>`
  display: flex;
  justify-content: ${({ $hasText }) => $hasText ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${Sizes.maxWidth}px;

  small {
    max-width: 50%;
    font-size: ${FontSize.Default};

    ${mediaQueries(
      'lg',
      css`
        max-width: 80%;
      `
    )}
  }
`;

export const FooterLink = styled(Link)<FooterLinkTypes>`
  position: relative;
  ${({ $isActive }) => generateHighlight($isActive)}
`;
