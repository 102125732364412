
export const validateUrl = (url: string) => {
  const regex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
  return {
    isValid: regex.test(url)
  };
}

export const getValidUrl = (url: string, locale: string) => {
  const { isValid } = validateUrl(url);
  if (isValid) return url;
  return `${locale === 'en' ? '/en' : ''}${url}`
};