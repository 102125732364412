'use client';

import { useParams } from 'next/navigation';

import {
  FooterSpecificFragment,
  NavigationAndFooterFragment,
  NavigationMenuFragment,
} from '../navigation-menu/navigation-menu-gql';
import {
  FooterContainer,
  FooterInformation,
  FooterLink,
  FooterLinks,
  Impressum,
  LinksContainer,
  LogoContainer,
} from './footer.styles';
import { ButtonType, ButtonsList, LinkType, LinksList } from './types';
import { Button, Column, Grid, Image, Text } from '@aurora-components';
import { FragmentType, getFragmentData } from '@gql';
import { IcelandAirLogo, LogoIcon } from 'icons/components';
import { getImage } from 'utils/get-image';
import { getValidUrl } from 'utils/validateUrl';

import { CloudinaryCropResize, CloudinaryGravity } from 'components/aurora/image/types';

type CustomFooterLinkTypes = {
  locale: string;
  link: LinkType | ButtonType;
  asButton?: boolean;
  slug?: string;
  tabIndex?: number;
};

const CustomFooterLink = ({ locale, link, asButton, slug, tabIndex }: CustomFooterLinkTypes) => {
  const internalConnection = link.cta?.internal_linkConnection?.edges ?? [];
  const hasInternalConnection = internalConnection.length > 0;

  const href = hasInternalConnection ? internalConnection[0]?.node?.url : link.cta?.external_link?.href;
  const internalTitle = internalConnection[0]?.node?.navigation_title;

  if (asButton) {
    const button = link as ButtonType;
    return (
      <Button
        className="footer-button"
        as="a"
        $variant={button.cta?.button_style}
        href={getValidUrl(href || '', locale)}
        target={hasInternalConnection ? '_self' : '_blank'}
        tabIndex={tabIndex}
      >
        {link.cta?.label ?? internalTitle}
      </Button>
    );
  }

  return (
    <FooterLink
      className="footer-link"
      href={`${locale === 'en' ? '/en' : ''}${href}`}
      $isActive={href === `/${slug}`}
      tabIndex={tabIndex}
    >
      {link.cta?.label ?? internalTitle}
      <span />
    </FooterLink>
  );
};

type Props = {
  locale: string;
} & FragmentType<typeof NavigationAndFooterFragment>;

export function Footer({ locale, ...props }: Props) {
  const nav = getFragmentData(NavigationAndFooterFragment, props);
  const { slug } = useParams() as { slug: string };

  if (!nav) return null;
  // Disabling rules-of-hooks here because getFragmentData actually isn't a fragment
  // But I can't find a way to rename it during the code generation
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const vildar_navigation = getFragmentData(NavigationMenuFragment, nav);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { headline, email, phone, impressum } = getFragmentData(FooterSpecificFragment, nav?.footer_specific!);

  const { assetConnection, alt_text, gravity, crop_resize } = vildar_navigation?.logo || {};
  const imageData = getImage(assetConnection);

  const linksList = vildar_navigation?.links as LinksList;
  const buttonsList = vildar_navigation?.buttons as ButtonsList;

  const hasImpressumText = !!impressum;

  function updateCopyright(originalCopyright: string) {
    const currentYear = new Date().getFullYear();
    const yearRegex = /(\d{4})-(\d{4})/;
    return originalCopyright.replace(yearRegex, `$1-${currentYear}`);
  }

  return (
    <FooterContainer>
      <Grid $fullWidth>
        <Column $span={{ xs: 0, lg: 2 }}>
          <LogoContainer>
            {vildar_navigation?.logo ? (
              <Image
                imageId={imageData?.id || ''}
                priority={true}
                alt={alt_text || ''}
                imageSuffix={imageData?.suffix || ''}
                gravity={gravity as CloudinaryGravity}
                crop_resize={crop_resize as CloudinaryCropResize}
              />
            ) : (
              <LogoIcon
                width={173}
                height={205}
              />
            )}
          </LogoContainer>
        </Column>

        <Column $span={{ xs: 12, lg: 6 }}>
          <FooterInformation>
            {headline && (
              <Text
                $variant="h2"
                $color="primary-base100"
                $fontWeight="Normal"
                $noBalancer
              >
                {headline}
              </Text>
            )}
            {email && (
              <Text
                $color="primary-base100"
                $fontWeight="Light"
                $noBalancer
              >
                {email}
              </Text>
            )}
            {phone && (
              <Text
                $color="primary-base100"
                $fontWeight="Light"
                $noBalancer
              >
                {phone}
              </Text>
            )}
          </FooterInformation>
        </Column>

        <Column $span={{ xs: 12, lg: 4 }}>
          <FooterLinks>
            <LinksContainer>
              {linksList?.map((link: LinkType, index) => (
                <CustomFooterLink
                  key={link.cta?.label}
                  locale={locale}
                  link={link}
                  slug={slug}
                  tabIndex={index}
                />
              ))}
            </LinksContainer>

            <LinksContainer $buttonsList>
              {buttonsList?.map((button: ButtonType, index) => (
                <CustomFooterLink
                  key={button.cta?.label}
                  locale={locale}
                  link={button}
                  asButton
                  tabIndex={index}
                />
              ))}
            </LinksContainer>
          </FooterLinks>
        </Column>
      </Grid>
      <Impressum $hasText={hasImpressumText}>
        {hasImpressumText && (
          <Text
            $variant="small"
            $color="primary-base100"
            $fontWeight="Light"
            $noBalancer
          >
            {updateCopyright(impressum)}
          </Text>
        )}
        <IcelandAirLogo />
      </Impressum>
    </FooterContainer>
  );
}
