"use client";

import { createGlobalStyle, css } from 'styled-components';
import { Colors } from 'styles/enums';

const defaultStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
    height: auto;
    background-color: ${Colors.UI.beigeWhite};
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
  }

  * {
    box-sizing: border-box;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${defaultStyles}
`;
